import { Modal, Text, useMantineTheme } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import * as React from 'react'

import { RequestPasswordResetCard } from './RequestPasswordResetCard'
import { AuthStateEnum, SignInCard } from './SignInCard'
import { SignUpCard } from './SignUpCard'

interface Props {
  children: React.ReactElement<React.DOMAttributes<HTMLButtonElement>>
  isDefaultSignup?: boolean
}

const getComponent = (authState: AuthStateEnum) => {
  switch (authState) {
    case AuthStateEnum.SignUp:
      return { Component: SignUpCard, title: 'Sign Up' }
    case AuthStateEnum.RequestPasswordReset:
      return { Component: RequestPasswordResetCard, title: 'Request Password Reset' }
    default:
      return { Component: SignInCard, title: 'Sign In' }
  }
}

export const AuthCard: React.FC<Props> = ({ children, isDefaultSignup = false }) => {
  const [authState, setAuthState] = React.useState<AuthStateEnum>(AuthStateEnum.SignIn)
  const [opened, { open, close }] = useDisclosure(false)
  const theme = useMantineTheme()

  const { Component, title } = getComponent(authState)

  return (
    <>
      {React.cloneElement<React.DOMAttributes<HTMLButtonElement>>(children, {
        onClick: () => {
          open()
          setAuthState(isDefaultSignup ? AuthStateEnum.SignUp : AuthStateEnum.SignIn)
        },
      })}
      <Modal
        opened={opened}
        onClose={close}
        title={<Text size="xl">{title}</Text>}
        overlayProps={{
          color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[1],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <Component setAuthState={setAuthState} />
      </Modal>
    </>
  )
}
