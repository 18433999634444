import { Anchor, Box, Button, Stack, Text, TextInput, rem } from '@mantine/core'
import { useForm } from '@mantine/form'
import * as React from 'react'

import { useRequestPasswordResetMutation } from '../../../generated/graphql'
import { AuthStateEnum } from './SignInCard'

interface Props {
  setAuthState: (state: AuthStateEnum) => void
}

interface FormValues {
  email: string
}

export const RequestPasswordResetCard: React.FC<Props> = ({ setAuthState }) => {
  const [isRequestSuccessful, setIsRequestSuccessful] = React.useState<boolean>(false)
  const [requestPasswordReset, { loading }] = useRequestPasswordResetMutation()
  const form = useForm<FormValues>({
    initialValues: { email: '' },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  })

  const handleFormSubmit = async (values: FormValues) => {
    try {
      await requestPasswordReset({ variables: values })
      setIsRequestSuccessful(true)
    } catch {}
  }

  return (
    <Box h={rem(141)}>
      {isRequestSuccessful ? (
        <Stack>
          <Text>Please check you email for instructions on how to reset your password</Text>
          <Anchor size="sm" component="button" onClick={() => setAuthState(AuthStateEnum.SignIn)}>
            Back to Sign In
          </Anchor>
        </Stack>
      ) : (
        <form onSubmit={form.onSubmit(handleFormSubmit)}>
          <Stack>
            <TextInput
              variant="filled"
              placeholder="Email"
              required
              {...form.getInputProps('email')}
            />
            <Button type="submit" fullWidth loading={loading}>
              Request Password Reset
            </Button>
            <Anchor size="sm" component="button" onClick={() => setAuthState(AuthStateEnum.SignIn)}>
              Back to Sign In
            </Anchor>
          </Stack>
        </form>
      )}
    </Box>
  )
}
