import { ActionIcon, ActionIconProps } from '@mantine/core'
import { IconBookmark, IconBookmarkOff, TablerIconsProps } from '@tabler/icons-react'
import React from 'react'

import {
  ConversationMessageAuthorEnum,
  useEditConversationMessageMutation,
} from '../../../generated/graphql'
import { useAuth } from '../../utils/auth'
import { AuthCard } from '../AuthCard'

export interface Message {
  text: string
  id: string
  createdAt: Date
  isBookmarked: boolean
  author: ConversationMessageAuthorEnum
  isPlaceholder?: boolean
}

interface Props extends ActionIconProps {
  id: string
  isBookmarked: boolean
  onMessageBookmark: (messageId: string) => void
  BookmarkedIcon?: (props: TablerIconsProps) => JSX.Element
  NotBookmarkedIcon?: (props: TablerIconsProps) => JSX.Element
}

export const CommentBookmark: React.FC<Props> = ({
  id,
  BookmarkedIcon = IconBookmarkOff,
  NotBookmarkedIcon = IconBookmark,
  isBookmarked,
  onMessageBookmark,
  ...rest
}) => {
  const {
    data: { User },
  } = useAuth()
  const [editConversationMessage, { loading }] = useEditConversationMessageMutation()

  const Icon = isBookmarked ? BookmarkedIcon : NotBookmarkedIcon

  if (!User?.id) {
    return (
      <AuthCard>
        <ActionIcon {...rest}>
          <Icon size="1rem" />
        </ActionIcon>
      </AuthCard>
    )
  }
  return (
    <ActionIcon
      {...rest}
      loading={loading}
      onClick={() =>
        !loading &&
        void editConversationMessage({
          variables: { id, inputData: { isBookmarked: !isBookmarked } },
          onCompleted: ({ editConversationMessage }) =>
            editConversationMessage && onMessageBookmark(editConversationMessage.id),
        })
      }
    >
      <Icon size="1rem" />
    </ActionIcon>
  )
}
