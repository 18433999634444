import { Anchor, Button, PasswordInput, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import * as React from 'react'

import { usePasswordSignUpMutation } from '../../../generated/graphql'
import { RefetchQueriesEnum } from '../../utils/constants'
import { AuthStateEnum } from './SignInCard'

interface Props {
  setAuthState: (state: AuthStateEnum) => void
}

export const SignUpCard: React.FC<Props> = ({ setAuthState }) => {
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (value) =>
        value.trim().length >= 6 ? null : 'Password should be at least 6 characters',
      confirmPassword: (value, values) =>
        value === values.password ? null : 'Passwords do not match',
    },
  })

  const [passwordSignUp, { loading }] = usePasswordSignUpMutation()

  const handlePasswordSignUp = ({ email, password }: { email: string; password: string }) =>
    passwordSignUp({
      variables: { inputData: { email, password } },
      refetchQueries: [RefetchQueriesEnum.Me],
    })

  return (
    <form id="signup" onSubmit={form.onSubmit(handlePasswordSignUp)}>
      <TextInput variant="filled" placeholder="Email" required {...form.getInputProps('email')} />
      <PasswordInput
        variant="filled"
        placeholder="Password"
        required
        mt="md"
        {...form.getInputProps('password')}
      />
      <PasswordInput
        variant="filled"
        placeholder="Confirm Password"
        required
        mt="md"
        {...form.getInputProps('confirmPassword')}
      />

      <Text color="dimmed" size="xs" mt="md">
        By signing up you agree with{' '}
        <Anchor href="/tos" target="_blank">
          Terms & Services / Privacy policy
        </Anchor>
      </Text>

      <Button type="submit" fullWidth mt="lg" loading={loading}>
        Sign up
      </Button>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Already have an account?{' '}
        <Anchor size="sm" component="button" onClick={() => setAuthState(AuthStateEnum.SignIn)}>
          Sign In
        </Anchor>
      </Text>
    </form>
  )
}
