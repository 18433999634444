import { Anchor, Button, Group, PasswordInput, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import * as React from 'react'

import { useSignInWithPasswordMutation } from '../../../generated/graphql'
import { RefetchQueriesEnum } from '../../utils/constants'

interface Props {
  setAuthState: (state: AuthStateEnum) => void
}

export enum AuthStateEnum {
  SignUp = 'SignUp',
  SignIn = 'SignIn',
  RequestPasswordReset = 'RequestPasswordReset',
}

export const SignInCard: React.FC<Props> = ({ setAuthState }) => {
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  })

  const [signIn, { loading }] = useSignInWithPasswordMutation({
    refetchQueries: [RefetchQueriesEnum.Me],
  })

  const handlePasswordSignIn = ({ email, password }: { email: string; password: string }) =>
    signIn({ variables: { inputData: { email: email, password } } })

  return (
    <form id="signin" onSubmit={form.onSubmit(handlePasswordSignIn)}>
      <TextInput variant="filled" placeholder="Email" required {...form.getInputProps('email')} />
      <PasswordInput
        variant="filled"
        placeholder="Password"
        required
        mt="md"
        {...form.getInputProps('password')}
      />
      <Group position="right" mt="md">
        <Anchor
          component="button"
          size="sm"
          onClick={() => setAuthState(AuthStateEnum.RequestPasswordReset)}
        >
          Forgot password?
        </Anchor>
      </Group>
      <Button type="submit" fullWidth mt="xl" loading={loading}>
        Sign in
      </Button>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Do not have an account yet?{' '}
        <Anchor size="sm" component="button" onClick={() => setAuthState(AuthStateEnum.SignUp)}>
          Create account
        </Anchor>
      </Text>
    </form>
  )
}
