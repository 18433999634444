import { List, createStyles } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { Prism } from '@mantine/prism'
import { PrismProps } from '@mantine/prism/lib/Prism/Prism'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const useStyles = createStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'pre-wrap',
    fontSize: theme.fontSizes.md,

    p: {
      margin: 0,
    },
    '&& > * + *': {
      marginTop: theme.spacing.xs,
    },
  },

  smallFontSize: {
    fontSize: theme.fontSizes.sm,
  },
}))

interface Props {
  body: string
}

export const Markdown: React.FC<Props> = ({ body }) => {
  const { classes, cx, theme } = useStyles()
  const isLtSm = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const fonSize = isLtSm ? theme.fontSizes.sm : theme.fontSizes.md

  return (
    <ReactMarkdown
      className={cx(classes.content, { [classes.smallFontSize]: isLtSm })}
      remarkPlugins={[remarkGfm]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '')
          return !inline ? (
            <Prism
              {...props}
              fz={fonSize}
              language={(match?.[1] as PrismProps['language']) || 'javascript'}
              w="100%"
            >
              {String(children).replace(/\n$/, '')}
            </Prism>
          ) : (
            <code {...props} className={className}>
              {children}
            </code>
          )
        },
        ol({ children }) {
          return (
            <List
              sx={{ display: 'flex', flexDirection: 'column' }}
              fz={fonSize}
              spacing="xs"
              type="ordered"
            >
              {children}
            </List>
          )
        },
        ul({ children }) {
          return (
            <List sx={{ display: 'flex', flexDirection: 'column' }} fz={fonSize} spacing="xs">
              {children}
            </List>
          )
        },
        li({ children }) {
          return (
            <List.Item sx={{ width: 'calc(100% - 1.25rem)' }} fz={fonSize}>
              {children}
            </List.Item>
          )
        },
      }}
    >
      {body}
    </ReactMarkdown>
  )
}
