import {
  ActionIcon,
  Avatar,
  Button,
  Card,
  CopyButton,
  Group,
  Highlight,
  Loader,
  Stack,
  Text,
  Title,
  Tooltip,
  createStyles,
  useMantineTheme,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconBookmark, IconChecks, IconCopy } from '@tabler/icons-react'
import React from 'react'

import { AuthCard } from '../AuthCard'
import { CommentBookmark } from './CommentBookmark'
import { Markdown } from './Markdown'

const useStyles = createStyles((theme) => ({
  comment: {
    padding: `${theme.spacing.lg} ${theme.spacing.xl}`,
    display: 'flex',
    flexDirection: 'column',
  },

  card: {
    width: '100%',
    display: 'flex',
  },
}))

interface CommentHtmlProps {
  postedAt: string
  body: string
  isMyMessage: boolean
  id: string
  isBookmarked: boolean
  hasBookmark?: boolean
  shouldRequestLogin: boolean
  isLoading: boolean
  onMessageBookmark: (messageId: string) => void
  author: {
    name: string
    image?: string
  }
}

export function CommentHtml({
  id,
  onMessageBookmark,
  postedAt,
  isBookmarked,
  hasBookmark = true,
  shouldRequestLogin,
  body,
  isLoading,
  author,
}: CommentHtmlProps) {
  const theme = useMantineTheme()
  const isLtSm = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const { classes } = useStyles()

  return (
    <Card
      padding="0"
      pt="xs"
      pb="lg"
      pr="1rem"
      radius="lg"
      className={classes.card}
      bg="transparent"
      sx={isLtSm ? { flexDirection: 'column' } : undefined}
    >
      <Avatar src={author.image ?? null} alt="" size="md" color="indigo" />

      <Stack
        ml={isLtSm ? undefined : 'xs'}
        w="100%"
        pt="xs"
        mt={isLtSm ? undefined : '-xs'}
        spacing="0.125rem"
        sx={{ flex: 1, overflow: 'hidden' }}
      >
        <Group spacing="xs" align="flex-start" h="1.75rem">
          <Group spacing="xs" align="center">
            <Text weight={500} lh={1}>
              {author.name}
            </Text>
            {!isLoading && (
              <Text color="dimmed" size="xs" lh={1}>
                {postedAt}
              </Text>
            )}
          </Group>

          {!isLoading && !shouldRequestLogin && (
            <Group ml="auto" spacing="xs" position="right" pos="relative" top="-0.5rem">
              <CopyButton value={body}>
                {({ copied, copy }) => (
                  <Tooltip label="Copied to clipboard" opened={copied}>
                    <ActionIcon variant="light" color={copied ? 'green' : 'blue'} onClick={copy}>
                      {copied ? <IconChecks size="1rem" /> : <IconCopy size="1rem" />}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
              {hasBookmark && (
                <CommentBookmark
                  id={id}
                  isBookmarked={isBookmarked}
                  variant={isBookmarked ? 'filled' : 'light'}
                  color="teal"
                  BookmarkedIcon={IconBookmark}
                  onMessageBookmark={onMessageBookmark}
                />
              )}
            </Group>
          )}
        </Group>
        {isLoading ? (
          <Loader variant="dots" />
        ) : (
          <>
            {shouldRequestLogin ? (
              <Stack maw="30rem" spacing="xl">
                <Title order={isLtSm ? 3 : 2} weight={500} lh={1}>
                  <Highlight
                    align="center"
                    lh={1.25}
                    highlight={[author.name]}
                    highlightStyles={(theme) => ({
                      backgroundImage: theme.fn.linearGradient(
                        45,
                        theme.colors.cyan[5],
                        theme.colors.indigo[5]
                      ),
                      fontWeight: 700,
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    })}
                  >
                    {`In order to continue in the conversation with ${author.name} you have to log in.`}
                  </Highlight>
                </Title>
                <Group position="center">
                  <AuthCard>
                    <Button
                      radius="sm"
                      variant="gradient"
                      gradient={{ from: 'indigo', to: 'cyan' }}
                    >
                      Sign in
                    </Button>
                  </AuthCard>
                  <AuthCard isDefaultSignup>
                    <Button radius="sm" color="blue" variant="subtle">
                      Sign up
                    </Button>
                  </AuthCard>
                </Group>
              </Stack>
            ) : (
              <Markdown body={body} />
            )}
          </>
        )}
      </Stack>
    </Card>
  )
}
